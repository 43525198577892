import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/src/layout.tsx";
import { Alert } from "@material-ui/lab";
import { ReservationForm } from "../../components/ReservationForm";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Content = makeShortcode("Content");
const Box = makeShortcode("Box");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Content mdxType="Content">
      <Box mb={4} mdxType="Box"><Alert variant="filled" severity={"success"} icon={false} mdxType="Alert">
          <p>{`Ďakujeme,`}<br />{`
Vašu požiadavku o rezerváciu sme prijali.`}<br />{`
Zakrátko ju potvrdíme emailom alebo telefonicky.`}</p>
          <p>{`V prípade akýchkoľvek otázok alebo nejasností nás neváhajte `}<a parentName="p" {...{
              "href": "/kontakt/"
            }}>{`kontaktovať`}</a>{`.`}</p>
        </Alert></Box>
      <ReservationForm variant="outlined" mdxType="ReservationForm" />
    </Content>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      